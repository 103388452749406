<!--
 * @Description: 主体级别
 * @Author: ChenXueLin
 * @Date: 2021-10-22 08:54:39
 * @LastEditTime: 2022-07-15 15:55:20
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="companyFullName">
              <el-input
                v-model.trim="searchForm.companyFullName"
                placeholder="主体全称"
                title="主体全称"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="companyLevel">
              <e6-vr-select
                v-model="searchForm.companyLevel"
                :data="companyLevelEnum"
                placeholder="主体等级"
                title="主体等级"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="remark">
              <el-input
                v-model="searchForm.remark"
                placeholder="备注"
                title="备注"
                maxlength="100"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="录入时间"
                ref="createTime"
                v-model="searchForm.createTime"
                title="录入时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="录入时间（始）"
                end-placeholder="创录入时间（止）"
              ></e6-date-picker>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i
            class="e6-icon-add_line"
            title="新增"
            @click="handleAdd('add')"
          ></i>
          <!-- <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i> -->
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          ref="tableList"
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <!-- <span v-if="column.fieldName === 'companyFullName'">
                <el-button type="text" @click="handleOperate({ id: 1 }, row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span> -->
              <span v-if="column.fieldName === 'companyLevel'">
                {{ getCompanyLevelName(row.companyLevel) }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 添加主体弹框 -->
    <el-dialog
      v-dialogDrag
      :title="type == 'add' ? '添加主体' : '编辑主体'"
      :visible.sync="addUserVisible"
      width="500px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      v-loading="editCustomerDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
    >
      <el-form
        ref="addCompanyForm"
        label-width="88px"
        :model="addCompanyForm"
        :rules="addCompanyRules"
      >
        <el-form-item label="主体全称" prop="companyFullName">
          <el-input
            v-model.trim="addCompanyForm.companyFullName"
            placeholder="主体全称"
            maxlength="64"
          ></el-input>
        </el-form-item>
        <el-form-item label="主体简称" prop="companySimName">
          <el-input
            v-model.trim="addCompanyForm.companySimName"
            placeholder="主体简称"
            maxlength="32"
          ></el-input>
        </el-form-item>
        <el-form-item label="主体等级" prop="companyLevel">
          <e6-vr-select
            v-model="addCompanyForm.companyLevel"
            :data="companyLevelEnum"
            placeholder="主体等级"
            title="主体等级"
            :props="{
              id: 'codeValue',
              label: 'codeName'
            }"
            clearable
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model.trim="addCompanyForm.remark"
            placeholder="备注"
            maxlength="100"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
import {
  getCompany,
  deleteCompany,
  exportCompany,
  createCompany,
  updateCompany,
  findDownList
} from "@/api";
export default {
  name: "customerMainBodyList",
  data() {
    return {
      searchForm: {
        companyFullName: "", //主体全称
        companyLevel: "", //主体等级
        createTime: [],
        startTime: "", //开始时间
        endTime: "", //结束时间
        remark: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },
      queryListAPI: getCompany,
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      companyLevelEnum: [], //主体等级枚举
      total: 0,
      columnData: [
        {
          fieldName: "companyId",
          display: true,
          fieldLabel: "主体ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "companyFullName",
          display: true,
          fieldLabel: "主体全称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "companySimName",
          display: true,
          fieldLabel: "主体简称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "companyLevel",
          display: true,
          fieldLabel: "主体等级",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedBy",
          display: true,
          fieldLabel: "修改人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          display: true,
          fieldLabel: "修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [{ companyId: 11 }], // 表格数据

      /*****添加/修改信息*******/
      addUserVisible: false,
      type: "add", //add新增edit编辑
      addCompanyForm: {
        companyFullName: "", //主体全称
        companySimName: "", //主体简称
        companyLevel: "", //主体等级
        remark: "" //备注
      },
      editCustomerDialogLoading: false,
      addCompanyRules: {
        companyFullName: [
          {
            required: true,
            message: "请填写主体全称",
            trigger: ["blur", "change"]
          }
        ],
        companySimName: [
          {
            required: true,
            message: "请填写主体简称",
            trigger: ["blur", "change"]
          }
        ],
        companyLevel: [
          {
            required: true,
            message: "请选择主体等级",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base, listPage, listPageReszie],

  computed: {},
  created() {
    this.queryList();
    this.initData();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startTime = val[0] || "";
        this.searchForm.endTime = val[1] || "";
      }
    }
  },
  methods: {
    // 获取基础信息(枚举)
    async initData() {
      try {
        //获取枚举数据 客户类型 客户类别 客户状态 客户业务类别
        let promiseList = [findDownList(["companyLevel"])];
        let [findDownListRes] = await Promise.all(promiseList);
        this.companyLevelEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.companyLevel"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 表格多选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.companyId;
      });
    },
    //批量删除
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除所选主体信息?", "删除主体信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteCompany();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //删除客户请求
    async deleteCompany() {
      try {
        this.loading = true;
        let res = await deleteCompany({
          ids: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      exportData(this, exportCompany);
    },
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.addCompanyForm = {
          ...row
        };
        this.addCompanyForm.companyLevel = row.companyLevel + "";
        this.handleAdd("edit");
      }
    },
    handleAdd(type) {
      this.addUserVisible = true;
      this.type = type;
    },
    getCompanyLevelName(val) {
      let target = this.companyLevelEnum.find(item => item.codeValue == val);
      if (target) {
        return target.codeName;
      }
      return val;
    },
    //点击确定添加
    handleSubmit() {
      this.$refs.addCompanyForm.validate(valid => {
        if (valid) {
          if (this.type == "add") {
            this.addCompany();
          } else {
            this.updateCompany();
          }
        }
      });
    },
    //添加主体信息请求
    async addCompany() {
      try {
        this.loading = true;
        let res = await createCompany(this.addCompanyForm);
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.queryList();
          this.closeDialog();
        } else {
          this.$message.erroe(res.msg);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //修改主体信息
    async updateCompany() {
      try {
        this.loading = true;
        let data = { ...this.addCompanyForm };
        delete data.updatedTime;
        delete data.updatedBy;
        delete data.createdTime;
        delete data.createdBy;
        let res = await updateCompany(data);
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.queryList();
          this.closeDialog();
        } else {
          this.$message.erroe(res.msg);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框重置form
    closeDialog() {
      this.addCompanyForm = {
        companyFullName: "", //主体全称
        companySimName: "", //主体简称
        companyLevel: "", //主体等级
        remark: "" //备注
      };
      this.$refs.addCompanyForm.resetFields();
      this.addUserVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-dialog__body {
    padding: 20px;
  }
  .el-form {
    .el-input {
      width: 250px;
    }
  }
}
</style>
